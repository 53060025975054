<template>
  <div class="flex flex-col">
    <ArenaGenericLayout
      :show-altinget-logo="config.public.site.locale !== 'no'"
      :show-mm-logo="config.public.site.locale === 'da'"
      :topbar-title="$t('Advertising')"
      :title="$t('Ads.AttentionMoney')"
      :description="$t('Ads.AttentionDemand')"
      :picture-url="$t('Ads.Image')"
    >
      <div>
        <h2 class="headline-xs">{{ $t('Ads.ContactUs') }}</h2>
        <div class="mt-6 -mx-4 md:mx-0">
          <CommonDivider />
          <div class="flex flex-row items-center">
            <CommonFontAwesomeIcon
              class="px-4 text-xl text-gray-500"
              icon="fa-regular fa-phone"
            />
            <div class="flex flex-col">
              <span class="text-gray-500">{{ $t('Phone') }}</span>
              <a :href="`tel:${$t('Ads.PhoneNumberFull')}`">{{
                $t('Ads.PhoneNumberWithSpaces')
              }}</a>
            </div>
          </div>
          <CommonDivider />
          <div class="flex flex-row items-center">
            <CommonFontAwesomeIcon
              class="px-4 text-xl text-gray-500"
              icon="fa-regular fa-envelope"
            />
            <div class="flex flex-col">
              <span class="text-gray-500">{{ $t('Email') }}</span>
              <a :href="`mailto:${$t('Ads.SiteEmail')}`">{{
                $t('Ads.SiteEmail')
              }}</a>
            </div>
          </div>
          <CommonDivider />
        </div>
      </div>
      <article class="whitespace-pre-line"></article>

      <ArenaSection>
        <h2 class="mb-6 headline-xs">{{ $t('Ads.Packages') }}</h2>
        <div v-if="config.public.site.locale === 'se'">
          <p>
            {{ $t('Ads.DidYouKnow') }}
          </p>
          <br />
          <em>Mer om våra läsare​</em>
          <div class="flex flex-row gap-x-4">
            <ul class="list-disc list-inside" v-html="$t('Ads.MoreLeft')" />
            <ul class="list-disc list-inside" v-html="$t('Ads.MoreRight')" />
          </div>
        </div>
        <p class="mt-4" v-html="$t('Ads.ReadMorePackages')"></p>
        <p class="mt-4" v-html="$t('Ads.MaterialSpecs')"></p>
      </ArenaSection>
      <ArenaSection v-if="config.public.site.locale === 'da'">
        <ArenaTestimonials
          :others-say="$t('OthersSayAds')"
          :testimonials="testimonials"
          full-width
        />
      </ArenaSection>
      <ArenaSection>
        <div class="flex flex-col space-y-2">
          <ArenaCollapsible class="py-6 border border-gray-300 rounded-lg">
            <div class="flex w-full pr-4">
              <div class="flex justify-center w-24">
                <CommonFontAwesomeIcon
                  class="pl-4 pr-8 text-4xl text-blue"
                  icon="fa-light fa-user"
                />
              </div>
              <div class="flex flex-col">
                <h2 class="font-sans text-xl font-bold">
                  {{ $t('Ads.JobAds') }}
                </h2>
                <span class="text-sm text-gray-500">{{
                  $t('Ads.ShownOn')
                }}</span>
              </div>
            </div>
            <template v-slot:details>
              <div class="flex mt-4">
                <div class="w-0 sm:w-24 shrink-0" />
                <div class="w-full px-4 sm:w-3/4 sm:px-0 content-dynamic">
                  <p v-html="$t('Ads.HardJob')"></p>
                  <p v-html="$t('Ads.WeDoHardJob')"></p>
                  <template v-if="config.public.site.locale === 'da'">
                    <h3 class="mt-6 mb-4 font-sans text-xl font-bold">
                      Pakker
                    </h3>
                    <div
                      class="rounded-lg bg-white shadow-[0px_1px_20px_2px_rgba(151,_151,_151,_0.2)] p-4"
                    >
                      <h3 class="font-sans font-bold text-blue">GRUNDPAKKEN</h3>
                      <p>
                        Når den rigtige kandidat kan findes inden for én
                        relevant niche.
                      </p>
                      <ul class="mt-4 text-sm">
                        <li
                          class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                        >
                          <div
                            class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                          >
                            <CommonFontAwesomeIcon
                              class="text-blue"
                              icon="fa-solid fa-check"
                            />
                          </div>
                          Publicering på Altingets forside med logo
                        </li>
                        <li
                          class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                        >
                          <div
                            class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                          >
                            <CommonFontAwesomeIcon
                              class="text-blue"
                              icon="fa-solid fa-check"
                            />
                          </div>
                          Målrettet publicering på nichemedie + nyhedsbrev
                        </li>
                        <li
                          class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                        >
                          <div
                            class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                          >
                            <CommonFontAwesomeIcon
                              class="text-blue"
                              icon="fa-solid fa-check"
                            />
                          </div>
                          Artikelvisning på valgt nichemedie
                        </li>
                        <li class="flex flex-row items-center py-3 pl-2">
                          <div
                            class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                          >
                            <CommonFontAwesomeIcon
                              class="text-blue"
                              icon="fa-solid fa-check"
                            />
                          </div>
                          <span>Op til <strong>14 dages visning</strong></span>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="rounded-lg bg-white shadow-[0px_1px_20px_2px_rgba(151,_151,_151,_0.2)] p-4 mt-6"
                    >
                      <h3 class="font-sans font-bold text-blue">MELLEMPAKKE</h3>
                      <p>
                        Find den rigtige kandidat med eksponering i flere
                        udvalgte nicher.
                      </p>
                      <ul class="mt-4 text-sm">
                        <li
                          class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                        >
                          <div
                            class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                          >
                            <CommonFontAwesomeIcon
                              class="text-blue"
                              icon="fa-solid fa-check"
                            />
                          </div>
                          Publicering på Altingets forside med logo
                        </li>
                        <li
                          class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                        >
                          <div
                            class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                          >
                            <CommonFontAwesomeIcon
                              class="text-blue"
                              icon="fa-solid fa-check"
                            />
                          </div>
                          <span
                            >Målrettet publicering på op til
                            <strong>3 nichemedier</strong> + nyhedsbreve</span
                          >
                        </li>
                        <li
                          class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                        >
                          <div
                            class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                          >
                            <CommonFontAwesomeIcon
                              class="text-blue"
                              icon="fa-solid fa-check"
                            />
                          </div>
                          Artikelvisning på valgt nichemedie
                        </li>
                        <li class="flex flex-row items-center py-3 pl-2">
                          <div
                            class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                          >
                            <CommonFontAwesomeIcon
                              class="text-blue"
                              icon="fa-solid fa-check"
                            />
                          </div>
                          <span>Op til <strong>28 dages visning</strong></span>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="rounded-lg bg-white shadow-[0px_1px_20px_2px_rgba(151,_151,_151,_0.2)] p-4 mt-6"
                    >
                      <h3 class="font-sans font-bold text-blue">TOTALPAKKE</h3>
                      <p>
                        Find den rigtige kandidat med maksimal eksponering på
                        tværs af faggrænserne.
                      </p>
                      <ul class="mt-4 text-sm">
                        <li
                          class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                        >
                          <div
                            class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                          >
                            <CommonFontAwesomeIcon
                              class="text-blue"
                              icon="fa-solid fa-check"
                            />
                          </div>
                          Publicering på Altingets forside med logo
                        </li>
                        <li
                          class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                        >
                          <div
                            class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                          >
                            <CommonFontAwesomeIcon
                              class="text-blue"
                              icon="fa-solid fa-check"
                            />
                          </div>
                          <span
                            >Målrettet publicering på op til
                            <strong>5 nichemedier</strong> + nyhedsbreve</span
                          >
                        </li>
                        <li
                          class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                        >
                          <div
                            class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                          >
                            <CommonFontAwesomeIcon
                              class="text-blue"
                              icon="fa-solid fa-check"
                            />
                          </div>
                          Artikelvisning på valgt nichemedie
                        </li>
                        <li
                          class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                        >
                          <div
                            class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                          >
                            <CommonFontAwesomeIcon
                              class="text-blue"
                              icon="fa-solid fa-check"
                            />
                          </div>
                          Mulighed for videoannonce
                        </li>
                        <li
                          class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                        >
                          <div
                            class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                          >
                            <CommonFontAwesomeIcon
                              class="text-blue"
                              icon="fa-solid fa-check"
                            />
                          </div>
                          Fremhævet annonce
                        </li>
                        <li
                          class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                        >
                          <div
                            class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                          >
                            <CommonFontAwesomeIcon
                              class="text-blue"
                              icon="fa-solid fa-check"
                            />
                          </div>
                          Bringes i Altinget Magasin og Mandag Morgen
                        </li>
                        <li class="flex flex-row items-center py-3 pl-2">
                          <div
                            class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                          >
                            <CommonFontAwesomeIcon
                              class="text-blue"
                              icon="fa-solid fa-check"
                            />
                          </div>
                          <span><strong>Til stilling er besat</strong></span>
                        </li>
                      </ul>
                    </div>
                    <h3 class="mt-6 mb-4 font-sans text-xl font-bold">
                      Tilkøb
                    </h3>
                    <div
                      class="flex flex-row items-center py-4 pl-6 mt-2 text-sm border border-gray-300 rounded-lg"
                    >
                      <CommonFontAwesomeIcon
                        class="pr-6 text-xl text-red"
                        icon="fa-light fa-play"
                      />
                      Videoopslag i annoncen
                    </div>
                    <div
                      class="flex flex-row items-center py-4 pl-6 mt-2 text-sm border border-gray-300 rounded-lg"
                    >
                      <CommonFontAwesomeIcon
                        class="pr-6 text-xl text-red"
                        icon="fa-light fa-user"
                      />
                      Jobannonce på MM.dk
                    </div>
                    <div
                      class="flex flex-row items-center py-4 pl-6 mt-2 text-sm border border-gray-300 rounded-lg"
                    >
                      <CommonFontAwesomeIcon
                        class="pr-6 text-xl text-red"
                        icon="fa-light fa-bullseye-arrow"
                      />
                      Bannere på Altinget.dk
                    </div>
                    <div
                      class="flex flex-row items-center py-4 pl-6 mt-2 text-sm border border-gray-300 rounded-lg"
                    >
                      <CommonFontAwesomeIcon
                        class="pr-6 text-xl text-red"
                        icon="fa-light fa-calendar"
                      />
                      Forlængelse pr. uge
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </ArenaCollapsible>
          <ArenaCollapsible class="py-6 border border-gray-300 rounded-lg">
            <div class="flex w-full pr-4">
              <div class="flex justify-center w-24">
                <CommonFontAwesomeIcon
                  class="pl-4 pr-8 text-4xl text-blue"
                  icon="fa-light fa-desktop"
                />
              </div>
              <div class="flex flex-col">
                <h2 class="font-sans text-xl font-bold">
                  {{ $t('Ads.BannerAds') }}
                </h2>
                <span class="text-sm text-gray-500">{{
                  $t('Ads.ShownOn')
                }}</span>
              </div>
            </div>
            <template v-slot:details>
              <div class="flex mt-4">
                <div class="w-0 sm:w-24 shrink-0" />
                <div class="w-full px-4 sm:w-3/4 sm:px-0 content-dynamic">
                  <p>
                    {{ $t('Ads.InfluencePeople') }}
                  </p>
                  <p v-html="$t('Ads.OurReadersGood')"></p>
                </div>
              </div>
            </template>
          </ArenaCollapsible>
          <ArenaCollapsible
            class="py-6 border border-gray-300 rounded-lg"
            v-if="config.public.site.locale === 'da'"
          >
            <div class="flex w-full pr-4">
              <div class="flex justify-center w-24">
                <CommonFontAwesomeIcon
                  class="pl-4 pr-8 text-4xl text-blue"
                  icon="fa-light fa-file-alt"
                />
              </div>
              <div class="flex flex-col">
                <h2 class="font-sans text-xl font-bold">Magasinannoncer</h2>
                <span class="text-sm text-gray-500">
                  Magasinannonce i Ugebrevet Mandag Morgen
                </span>
              </div>
            </div>
            <template v-slot:details>
              <div class="flex mt-4">
                <div class="w-0 sm:w-24 shrink-0" />
                <div class="w-full px-4 sm:w-3/4 sm:px-0">
                  <p>
                    Få jeres budskab ud til beslutningstagere i vores fysiske
                    ugebrev – i et format med begrænset annoncemængde, så
                    annoncen ikke drukner i annoncestøj. Ugebrevet udkommer 40
                    gange om året og læses primært af ledere og specialister.
                    Derudover lander Ugebrevet blandt ministre,
                    folketingsmedlemmer og byrådsmedlemmer.
                  </p>
                  <p>
                    Kontakt os for udgivelsesdatoer og materialefrist for
                    Ugebrevet Mandag Morgen.
                  </p>
                </div>
              </div>
            </template>
          </ArenaCollapsible>
          <ArenaCollapsible class="py-6 border border-gray-300 rounded-lg">
            <div class="flex w-full pr-4">
              <div class="flex justify-center w-24">
                <CommonFontAwesomeIcon
                  class="pl-4 pr-8 text-4xl text-blue"
                  icon="fa-light fa-ad"
                />
              </div>
              <div class="flex flex-col">
                <h2 class="font-sans text-xl font-bold">
                  {{ $t('Ads.NativeAds') }}
                </h2>
                <span class="text-sm text-gray-500">
                  {{ $t('Ads.ShownOn') }}
                </span>
              </div>
            </div>
            <template v-slot:details>
              <div class="flex mt-4">
                <div class="w-0 sm:w-24 shrink-0" />
                <div class="w-full px-4 sm:w-3/4 sm:px-0">
                  <p v-html="$t('Ads.NativeAdsContent')" />
                </div>
              </div>
            </template>
          </ArenaCollapsible>
        </div>
      </ArenaSection>
      <template #right>
        <ArenaSignup
          :title="$t('Ads.KnowMore')"
          :description="$t('Ads.PriceContactUs')"
          :person="contactPerson"
          :submit-lead="submitContactModal"
          :secondary-button-action="() => (showContactModal = true)"
        >
          <template v-slot:mainButtonContent>
            <a
              class="block w-full h-full text-center leading-9"
              :href="`tel:${$t('Ads.PhoneNumberFull')}`"
            >
              <CommonFontAwesomeIcon
                class="text-white mr-2 !align-middle"
                icon="fa-solid fa-phone"
              />
              {{ $t('Ads.PhoneNumberWithSpaces') }}</a
            >
          </template>
          <template v-slot:secondaryButtonContent>{{
            $t('Ads.CallMe')
          }}</template>
        </ArenaSignup>
      </template>
    </ArenaGenericLayout>
    <div class="container my-28">
      <div
        class="flex flex-col md:flex-row space-y-6 md:space-y-0 md:justify-between md:space-x-4"
      >
        <div class="flex flex-col items-center justify-center space-y-8">
          <CommonFontAwesomeIcon
            class="text-4xl text-red md:text-7xl"
            icon="fa-thin fa-eye"
          />
          <span class="text-center" v-html="$t('Ads.USP1')" />
        </div>
        <div class="flex flex-col items-center justify-center space-y-8">
          <CommonFontAwesomeIcon
            class="text-4xl text-red md:text-7xl"
            icon="fa-thin fa-bullseye-arrow"
          />
          <span class="text-center" v-html="$t('Ads.USP2')" />
        </div>
        <div class="flex flex-col items-center justify-center space-y-8">
          <div>
            <CommonFontAwesomeIcon
              class="text-4xl text-red md:text-7xl"
              icon="fa-thin fa-mobile"
            />
            <CommonFontAwesomeIcon
              class="ml-2 text-4xl text-red md:text-7xl"
              icon="fa-thin fa-newspaper"
            />
          </div>
          <span class="text-center" v-html="$t('Ads.USP3')" />
        </div>
      </div>
    </div>
    <ArenaContactModal
      v-if="showContactModal"
      :person="contactPerson"
      @modal-close="showContactModal = false"
      :submit-lead="submitContactModal"
    />
    <div
      class="fixed bottom-0 sm:hidden shadow-t-md w-full bg-white z-[60] h-17 flex items-center justify-between px-3"
    >
      <div class="flex flex-row w-full space-x-4">
        <button
          class="flex items-center justify-center mr-2 bg-gray-300 rounded-full h-9 w-9"
          @click="showContactModal = true"
        >
          <CommonFontAwesomeIcon
            class="text-sm text-gray-700"
            icon="fa-regular fa-info-circle"
          />
        </button>
        <CommonButton
          bg="bg-blue"
          text="text-white"
          border="border-none"
          class="flex-1"
        >
          <a
            class="block w-full h-full text-center leading-9"
            :href="`tel:${$t('Ads.PhoneNumberFull')}`"
          >
            <CommonFontAwesomeIcon icon="fa-solid fa-phone" class="mr-3" />
            {{ $t('Ads.PhoneNumberWithSpaces') }}</a
          >
        </CommonButton>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { HotshotUser, Lead } from '~/typesAuto/apicore/v1'

const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()

useHead({
  title: nuxtApp.$t('Ads.Title'),
  meta: [
    {
      name: 'og:description',
      hid: 'og:description',
      content: nuxtApp.$t('Ads.MetaDescription'),
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: nuxtApp.$t('Ads.Title'),
    },
    {
      name: 'description',
      hid: 'description',
      content: nuxtApp.$t('Ads.MetaDescription'),
    },
    {
      name: 'title',
      hid: 'title',
      content: nuxtApp.$t('Ads.Title'),
    },
  ],
})
const videoPlaceholder = `
<div class="lg:w-64 xl:w-72 2xl:w-96 w-full pt-[56.25%] relative overflow-hidden"><iframe title="vimeo-player" class="absolute top-0 bottom-0 left-0 right-0 w-full h-full" src="https://player.vimeo.com/video/539652299?h=6cf42f81ff" frameborder="0" allowfullscreen></iframe></div>
`
const testimonials = [
  {
    text: 'Vi fik et bredt ansøgerfelt med mange kvalificerede ansøgere.',
    name: 'Mads Bo Schear Mikkelsen',
    personTitle: 'Vicemuseumschef for Statens naturhistoriske museum',
    extraContent: videoPlaceholder,
    image: 'https://legacy.altinget.dk/images/archive/98625.jpg',
  },
]
const showContactModal = ref(false)
const contactPerson = {
  mobile: nuxtApp.$t('Ads.PhoneNumberFull'),
  email: nuxtApp.$t('Ads.ContactEmail'),
  name: nuxtApp.$t('Ads.ContactName'),
  title: nuxtApp.$t('Ads.ContactTitle'),
  image:
    config.public.site.locale === 'se'
      ? 'https://legacy.altinget.se/images/Writers/111-carl-eyton-2-96.jpg'
      : undefined,
} as HotshotUser
const submitContactModal = async (lead: Lead): Promise<boolean> => {
  try {
    await nuxtApp.$api.leads.submitAdsLead(lead)
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
</script>
